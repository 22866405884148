import React from 'react'

import * as styles from '../assets/styles.module.scss'
import { default as Header } from '../components/header'
import { default as Footer } from '../components/footer'

const Terms = () => {
    return (
        <section className={styles.Container}>
            <Header />
            <div className={styles.ModelPage}>
                <section className={styles.Centered}>
                    <h1>Terms of Service</h1>
                </section>
            </div>
            <Footer />
        </section>
    )
}

export default Terms